.LifeStyle {
    display: flex;
    gap: 1rem;
    padding-top: 4rem;
}

.lifeStyleLeftSideContainer {
    width: 70%;
}

.lifeStyleRightSideContainer {
    width: 30%;
}

.newsLetterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(208, 201, 192);
    width: 100%;
    height: 17rem;
    gap: 1rem;
}

.subButton {
    background-color: red;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.7rem;
    color: #fff;
}

.subButton:hover {
    opacity: 0.7;
}

.lifeStyleMainArticleContainer {
    width: 50%;
}

.lifeStyleMainContentContainer {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
}

.lifeStyleImageContainer {
    height: 17rem;
    object-fit: cover;
}

.lifeStyleContentExtrasContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 4rem;
    gap: 2rem;
}

.lifeStyleInfoContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media(max-width: 1028px) {
    .LifeStyle {
        display: flex;
        flex-direction: column;
    }

    .lifeStyleContentExtrasContainer {
        display: flex;
        flex-direction: column;
    }

    .lifeStyleMainContentContainer {
        display: flex;
        flex-direction: column;
    }

    .lifeStyleMainContentContainer > div {
        width: 100%;
    }

    .newsLetterContainer {
        width: 100%;
    }

    .lifeStyleRightSideContainer {
        width: 100%;
    }

    .lifeStyleLeftSideContainer {
        width: 100%;
    }

    .lifeStyleContentContainer {
        font-size: smaller;
    }

    .newsLetterContainer {
        font-size: smaller;
    }

    .subInput {
        font-size: 1rem !important;
    }

}
