.DontMiss {
    padding-top: 2rem;
    display: flex;
    gap: 1rem;
}

.dMNavLink {
    padding: 0.7rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-size: 70%;
    color: #fff;
}

.dMNavLink:hover {
    opacity: 0.7;
    cursor: pointer;
}

.dMNavLink_1 {
    padding: 0.7rem;
    padding-top: 0.1rem;
    padding-bottom: 0.54rem;
    color: black;
    cursor: pointer;
    font-weight: bold;
}

.dMHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
}

.dMHeaderContainer::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, red, green, blue);
}

.dMHeaderContainer > h2 {
    color: red;
}

.dMNavLinks {
    display: flex;
    gap: 1rem;
}

.dontMissLeftContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.dMContentContainer {
    padding-top: 1rem;
    gap: 2rem;
    display: flex;
}

.dMContentLeftSide {
    width: 50%;
}

.dMCardImage {
    width: 10rem !important;
}

.dMCardImage > img {
    width: 10rem !important;
}

.dMContentRightSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dMContentLeftSide > img {
    width: 100%;
    height: 64%;
}

.DontMissCardItem {
    display: flex;
    gap: 1rem;
}

.DontMissCardItem:hover {
    cursor: pointer;
    opacity: 0.7;
}

.dontMissRightContainer {
    width: 30%;
}

.dMExtrasContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
}

@media(max-width: 1028px) {
    .dMNavLinks {
        display: flex;
        flex-wrap: wrap;
    }

    .dMHeaderContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .DontMiss {
        display: flex;
        flex-direction: column;
    }

    .dMContentContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .dMContentLeftSide {
        width: 100%;
    }

    .dontMissLeftContainer {
        width: 100%;
    }

    .dMContentRightSide {
        width: 100%;
        font-size: smaller;
    }

    .dontMissRightContainer {
        width: 100%;
    }

    .dMExtrasContainer {
        font-size: smaller;
    }
}