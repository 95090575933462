.FollowUs {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 4rem;
    width: 100%;
}

.FollowUs > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width: 1028px) {

    .FollowUs {
        display: flex;
        flex-direction: column;
    }

    .FollowUs > img {
        height: 200px;
    }

}